import { Card, Form, Input, Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import __ from "../../../app/i18n";
import { OptionSelect } from "./OptionSelect";

const placeholders = {
  number: {
    description:
      "Es el tamaño del equipo de ventas mencionado en la transcripción por el cliente.",
    name: "Size",
  },
  string: {
    name: "Provider",
    description:
      "Es el proveedor que utiliza el cliente para las llamadas mencionado en la transcripción por el cliente. Si no lo encontras, utiliza el valor 'No Mencionado'.",
    examples: ["Hubspot"],
  },
  uniqueOption: {
    name: "CRM",
    description:
      "Es el CRM mencionado en la transcripción utilizado por el cliente.",
    values: ["Hubspot", "Salesforce", "Pipedrive"],
  },
  array: {
    name: "Customer Platform",
    description:
      "¿Que plataforma utiliza para interactuar con sus clientes?",
    values: ["Hubspot Calling", "Whatsapp", "Google Meet", "Microsoft Teams"],
  },
  boolean: {
    name: "Interest Product",
    description:
      "¿El cliente mostro interés en el producto?",
  },
};

/**
 * type: number/string/enum
 *
 */
const types = {
  number: __("apps.extractor.property.types.number"),
  string: __("apps.extractor.property.types.string"),
  uniqueOption: __("apps.extractor.property.types.uniqueOption"),
  array: __("apps.extractor.property.types.multipleOption"),
  boolean: __("apps.extractor.property.types.boolean"),
};

function Property(props) {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [type, setType] = useState(props.type);
  const [values, setValues] = useState(props.values || []);
  const [placeHolder, setPlaceholder] = useState(placeholders["uniqueOption"]);
  const [examples, setExamples] = useState(props.examples || []);

  useEffect(() => {
    props.onUpdateProperty({
      id: props.id,
      index: props.index,
      examples,
      name,
      description,
      type,
      values,
    });
  }, [type, name, description, values, examples]);

  return (
    <Card
      size="small"
      title={name.toUpperCase()}
      key={props.index}
      style={{ marginBottom: "15px" }}
      extra={<CloseOutlined onClick={() => props.onRemove(props.id)} />}
      headStyle={{ background: "#eee" }}
    >
      <Form layout="vertical">
        <Form.Item label="Tipo de propiedad" required>
          <Select
            style={{ width: "200px" }}
            value={type}
            onChange={(value) => {
              setType(value);
              setPlaceholder(placeholders[value]);
              if (value !== "uniqueOption" || value !== "array") {
                setValues([]);
              }
            }}
          >
            {Object.entries(types).map(([key, label]) => (
              <Select.Option key={key} value={key}>
                {label.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Nombre de la propiedad" required>
          <Input
            key={"name"}
            value={name}
            placeholder={placeHolder.name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Descripción de la propiedad (muy importante que brindes el mayor detalle posible)"
          required
        >
          <Input.TextArea
            key={[props.index, "desc"].join("#")}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{ display: "block", width: "100%" }}
            placeholder={placeHolder.description}
          />
        </Form.Item>

        {(type === "uniqueOption" || type === "array") && (
          <div>
            <Form.Item label="Posibles valores" required>
              <OptionSelect
                options={values}
                onRemove={(index) =>
                  setValues(values.filter((_, i) => i !== index))
                }
                onNew={(newOption) => setValues([...values, newOption])}
              />
            </Form.Item>
          </div>
        )}

        {type === "string" && (
          <Form.Item label="Ejemplos" required>
            <OptionSelect
              options={examples}
              onRemove={(index) =>
                setExamples(examples.filter((_, i) => i !== index))
              }
              onNew={(newExample) => setExamples([...examples, newExample])}
            />
          </Form.Item>
        )}
      </Form>
    </Card>
  );
}

export default Property;
