import { useGoogleLogin } from "@react-oauth/google";
import { Button, Typography } from "antd";
import googleLogo from "../profile/tabs/assets/googleLogo.png";

export default function GoogleLoginButton(props) {
  const login = useGoogleLogin({
    onSuccess: (data) => {
      props.onSuccess(data);
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
  });

  return (
    <Button
      style={{ width: "100%", padding: "5px", height: "auto" }}
      icon={<img src={googleLogo} width={"20px"} alt="Google Logo" />}
      onClick={() => login()}
    >
      <Typography.Text style={{ marginLeft: "10px" }}>
        Continuar con Google
      </Typography.Text>
    </Button>
  );
}
