import React, { useState } from 'react';
import { Layout, Typography, Table, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import __ from '../../../app/i18n';
import styles from "../team.module.css";
import NewTeam from "./newTeam";
import EditTeam from './editTeam';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { selectUsers } from "../slice";
import { selectTeams, selectIsFetchingTeams, deleteTeam} from './slice';
import ManagerComponent from '../../session/ManagerComponent';

const { Content } = Layout;

const SECTIONS = {
  NEW_TEAM: "newTeam",
  EDIT_TEAM: "editTeam",
};

const formatUsersList = (userIds, allUsers, unknownLabel = 'Usuario desconocido') => {
  if (userIds.length === 0 || userIds.length === allUsers.length) {
    return "Todos";
  }

  const names = userIds.map(userId => {
    const user = allUsers.find(user => user.id === userId);
    return user ? user.name : unknownLabel;
  });
  const joinedNames = names.join(', ');

  return joinedNames.length > 32 ? `${joinedNames.substring(0, 32)}...` : joinedNames;
};

export default function ViewTeams() {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const teams = useSelector(selectTeams);
  const isFetchingTeams = useSelector(selectIsFetchingTeams);
  
  const [section, setSection] = useState("");
  const [teamForEdit, setTeamForEdit] = useState();

  const handleDeleteTeam = (id) => {
    dispatch(deleteTeam({ id }));
  };

  const handleEditTeam = (team) => {
    setTeamForEdit(team);
    setSection(SECTIONS.EDIT_TEAM);
  };

  const columns = [
    {
      title: __("team.newTeam.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: __("team.newTeam.users"),
      key: 'users',
      render: (_, team) => formatUsersList(team.users, users)
    },
    {
      title: __("team.newTeam.managers"),
      key: 'managers',
      render: (_, team) => formatUsersList(team.managers, users, 'Manager desconocido')
    },
    {
      title: '',
      key: 'actions',
      render: (_, team) => (
        <div style={{ display: 'flex'}}>
          <EditOutlined style={{ fontSize: "20px" }} onClick={() => handleEditTeam(team)} />
          <DeleteOutlined style={{ marginLeft: '.5rem', fontSize: "20px", color: "red" }} onClick={() => handleDeleteTeam(team._id)} />
        </div>
      ),
    },
  ];

  return (
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
        <Typography.Title level={2}>{__("team.teams")}</Typography.Title>
        <Button
          onClick={() => setSection(SECTIONS.NEW_TEAM)}
          type="primary"
          className={styles.addTeamCircle}
        >
          +
        </Button>
      </div>
      {isFetchingTeams && <Table columns={columns} dataSource={teams} />}
      <ManagerComponent>
        <NewTeam
          open={section === SECTIONS.NEW_TEAM}
          onClose={() => setSection("")}
        />
        <EditTeam 
          open={section === SECTIONS.EDIT_TEAM}
          team={teamForEdit}  
          onClose={() => { setSection(""); setTeamForEdit(null); }}
        />
      </ManagerComponent>
    </Content>
  );
}
