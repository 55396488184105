import {
  Button,
  Card,
  Layout,
  Table,
  Modal,
  Dropdown,
  Badge,
  Menu,
  Tooltip,
  Typography,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import GoogleCalendarLogo from "../profile/tabs/assets/googleCalendarLogo.png";
import WhatsAppLogo from "../profile/tabs/assets/whatsAppIcon.png";
import { ShrinkOutlined, ArrowsAltOutlined, SearchOutlined } from '@ant-design/icons';
import TeamsLogo from "../profile/tabs/assets/teamsLogo.png";

import { isFetching } from "./filters/slice";
import { loadInteractionCallTypes } from "./interactionCallType/slice";
import { loadTeams } from "./teams/slice";
import {
  DeleteOutlined,
  WarningOutlined,
  ReloadOutlined,
  DownOutlined,
  LoadingOutlined,
  EditOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  deleteUser,
  loadUsers,
  resetPassword,
  selectUsers,
  toggleUserAccess,
} from "./slice";
import NewTeamUser from "./NewTeamUser";
import EditTeamUser from "./EditTeamUser";
import styles from "./team.module.css";
import ManagerComponent from "../session/ManagerComponent";
import __ from "../../app/i18n";
import Api from "../../app/api";
import { Language, AlertTriangle } from "tabler-icons-react";
import { appMeetingLangs } from "../../app/utils";
import ViewCallType from './interactionCallType/index';
import ViewTeams from './teams/index';
const { Content, Sider } = Layout;

const SECTIONS = {
  NEW_USER: "newUser",
  EDIT_USER: "editUser",
};

const loadingCards = (
  <>
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
    <Card loading={true} style={{ marginBottom: "15px" }} />
  </>
);

export default function TeamList() {
  const selectIsFetching = useSelector(isFetching);
  const [searchText, setSearchText] = useState('');
  const users = useSelector(selectUsers);
  const [section, setSection] = useState(false);
  const [userForDelete, setUserForDelete] = useState(false);
  const [userForEdit, setUserForEdit] = useState(false);
  const [googleConnections, setGoogleConnections] = useState(null);
  const [whatsAppConnections, setWhatsAppConnections] = useState([]);
  const [microsoftConnections, setMicrosoftConnections] = useState([]);
  const [usersWithoutScope, setUsersWithoutScope] = useState(null);
  const dispatch = useDispatch();
  document.title = `${__("appName")} - ${__("team.label")}`;
  const [collapsed, setCollapsed] = useState(false);
  const [renderActiveSection, setActiveSection] = useState('users');
  const [filteredUsers, setFilteredUsers] = useState(users);
  
  useEffect(() => {
    dispatch(loadUsers());
    dispatch(loadInteractionCallTypes());
    dispatch(loadTeams());
    Api('googleConnections', {}).then(data => {
      setGoogleConnections(data.usersWithGoogle);
      setWhatsAppConnections(data.usersWithWhatsApp);
      setMicrosoftConnections(data.usersWithMicrosoft);
      setUsersWithoutScope(data.usersWithGoogleWithoutScope);
    });
  }, []);

  useEffect(() => setFilteredUsers(users), [users]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase().trim();
    setSearchText(value);

    const filteredData = users.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setFilteredUsers(filteredData);
  };

  const handleNewUser = () => {
    setSection(SECTIONS.NEW_USER);
  };

  const handleEditUser = (user) => {
    setUserForEdit(user);
    setSection(SECTIONS.EDIT_USER);
  };

  const showUserDeleteModal = (id) => {
    setUserForDelete(id);
  };

  const handleToggleUserAccess = (userId) => {
    dispatch(toggleUserAccess({ id: userId }, { id: userId }));
  };

  const handleDeleteUser = () => {
    dispatch(deleteUser({ id: userForDelete }, {}));
    setUserForDelete(null);
  };

  const handleResetPassword = (userId) => {
    dispatch(resetPassword({ userId }));
  };

  const isGoogleConnected = (user) => {
    if (!googleConnections) {
      return <LoadingOutlined style={{ fontSize: 15 }} spin />
    }

    if (Array.isArray(googleConnections) && googleConnections.includes(user.id)) {
      return <img width="20px" src={GoogleCalendarLogo} title="Conectado a Google" alt="Conectado a Google"/>
    } else if (Array.isArray(usersWithoutScope) && usersWithoutScope.includes(user.id)) {
      return <Tooltip title={__("generic.buttons.needPermission")}>
        <div style={{ paddingLeft: "16px" }}>
          <AlertTriangle color="#faad15"/>
        </div>
      </Tooltip>
    }
  };
  const isWhatsAppConnected = (user) => {
    if (!googleConnections) {
      return <LoadingOutlined style={{ fontSize: 15 }} spin />
    }

    if (Array.isArray(whatsAppConnections) && whatsAppConnections.includes(user.id)) {
      return <img width="20px" src={WhatsAppLogo} title="WhatsApp" alt="WhatsApp"/>
    }
  };

  const isTeamsConnected = (user) => {
    if (!googleConnections) {
      return <LoadingOutlined style={{ fontSize: 15 }} spin />
    }

    if (Array.isArray(microsoftConnections) && microsoftConnections.includes(user.id)) {
      return <img width="20px" src={TeamsLogo} title="Microsoft Teams" alt="Microsoft Teams"/>
    }
  };

  const actions = (user) => {
    const menu = (
      <Menu>
        <Menu.Item
          icon={
            <EditOutlined style={{ fontSize: "20px" }} />
          }
          onClick={() => handleEditUser(user)}
        >
          {__("team.edit")}
        </Menu.Item>
        <Menu.Item
          hidden={!user.enabled}
          icon={
            <WarningOutlined style={{ fontSize: "20px", color: "#ffa800" }} />
          }
          onClick={() => handleToggleUserAccess(user.id)}
        >
          {__("team.deactivate")}
        </Menu.Item>

        <Menu.Item
          icon={
            <DeleteOutlined
              style={{
                fontSize: "20px",
                color: "red",
              }}
            />
          }
          onClick={() => showUserDeleteModal(user.id)}
        >
          {__("generic.fields.delete")}
        </Menu.Item>
        <Menu.Item
          icon={
            <ReloadOutlined
              style={{
                fontSize: "20px",
                color: "green",
              }}
            />
          }
          onClick={() => handleResetPassword(user.id)}
        >
          {__("generic.fields.resetPassword")}
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <a>
        {__("team.actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };
  const columns = [
    {
      title: __("team.list.name"),
      dataIndex: "name",
      key: "name",
      render: (name, user) => renderName(user),
      sorter: (a, b) => a.name < b.name ?  -1 : 1,
    },
    {
      title: __("team.list.email"),
      dataIndex: "email",
      key: "email",
      render: (name, user) => user.email,
      sorter: (a, b) => a.email < b.email ?  -1 : 1,
    },
    {
      title: __("team.list.role"),
      dataIndex: "role",
      key: "role",
      render: (name, user) =>
        __("roles." + String(user.roleName).toLowerCase()),
      sorter: (a, b) => a.roleName < b.roleName ?  -1 : 1,
      filters: [
        { text: __("roles.manager"), value: "manager" },
        { text: __("roles.seller"), value: "seller" },
      ],
      onFilter: (value, record) => record.roleName.toLowerCase() === value.toLowerCase(),
    },
    {
      title: __("team.list.status"),
      dataIndex: "status",
      key: "status",
      filters: [
        { text: __("team.list.active"), value: true },
        { text: __("team.list.deactive"), value: false },
      ],
      onFilter: (value, record) => record.enabled === value,
      render: (name, user) =>
        user.enabled ? (
          <>
            <Badge status="success" /> {__("team.list.active")}
          </>
        ) : (
          <>
            <Badge status="error" /> {__("team.list.deactive")}
          </>
        ),
      sorter: (a, b) => a.status < b.status ?  -1 : 1,
    },
    {
      title: <>
        <img width="20px" src={GoogleCalendarLogo} title="Conectado a Google" alt="Conectado a Google"/>
        <img width="20px" src={WhatsAppLogo} title="WhatsApp" alt="WhatsApp"/>
        <img width="20px" src={TeamsLogo} title="Teams" alt="Teams"/>
        </>,
      dataIndex: "recording",
      key: "recording",
      filters: [
        { text: <img width="20px" src={GoogleCalendarLogo} title="Conectado a Google" alt="Conectado a Google"/>, value: 'google' },
        { text: <img width="20px" src={WhatsAppLogo} title="WhatsApp" alt="WhatsApp"/>, value: 'whatsApp' },
        { text: <img width="20px" src={TeamsLogo} title="Teams" alt="Teams"/>, value: 'teams' },
      ],
      onFilter: (value, user) => {
        return (
          (value === 'google' && isGoogleConnected(user)) ||
          (value === 'whatsApp' && isWhatsAppConnected(user)) ||
          (value === 'teams' && isTeamsConnected(user))
        );
      },
      render: (_, user) => <>{isGoogleConnected(user)} {isWhatsAppConnected(user)} {isTeamsConnected(user)} </>,
    },
    {
      title: <Language style={{ height: "20px", "width": "20px", marginTop: "5px" }}/>,
      dataIndex: "lang",
      key: "lang",
      render: (_, user) => <span className={`flag flag-country-${appMeetingLangs.find(l => l.value === user.lang).flag}`}></span>,
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, user) => actions(user),
    },
  ];

  const renderName = (user) => {
    return (
      <>
        {initials(user)}
        {user.name}
      </>
    );
  };

  const renderUserDetails = (user) => {
    return (
      <p
        key={`expandable#${user.id}`}
        style={{
          margin: 0,
        }}
      >
        {user.id}
        {JSON.stringify(user, null, 2)}
      </p>
    );
  };

  const initials = (user) => {
    if (user.image) {
      return (
        <img
          className={styles.initialsBuble}
          src={user.image}
          alt={user.name}
        />
      );
    }

    const initials = user.name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return <span className={styles.initialsBuble}>{initials}</span>;
  };

  const renderContent = () => {
    switch (renderActiveSection) {
      case 'users':
        return (
          <>
            <Modal
              title={__("generic.fields.needConfirmTitle")}
              open={!!userForDelete}
              onOk={() => handleDeleteUser()}
              onCancel={() => setUserForDelete(null)}
              okText={__("generic.fields.confirm")}
              cancelText={__("generic.fields.cancel")}
            >
              <p>{__("generic.fields.needConfirmContent")}</p>
            </Modal>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem'}}>
              <Typography.Title level={2}>{__("team.users")}</Typography.Title>
              <span className={styles.topRightSection}>
                <Input
                  placeholder={__('team.list.search')}
                  value={searchText}
                  onChange={handleSearch}
                  style={{ width: 300, marginRight: "15px" }}
                  prefix={<SearchOutlined />}
                />
                <ManagerComponent>
                  <Button
                    onClick={handleNewUser}
                    type="primary"
                    className={styles.addTeamCircle}
                  >
                    +
                  </Button>
                </ManagerComponent>
              </span>
            </div>
            {selectIsFetching ? (
              loadingCards
            ) : (
              <Table
                key={"team:table"}
                columns={columns}
                dataSource={(filteredUsers || []).map((u) => ({ ...u, key: u.id }))}
              />
            )}
            <ManagerComponent>
              <NewTeamUser
                open={section === SECTIONS.NEW_USER}
                onClose={() => setSection("")}
              />
              <EditTeamUser
                open={section === SECTIONS.EDIT_USER && userForEdit}
                onClose={() => { setSection(); setUserForEdit() }}
                user={userForEdit}
              />
            </ManagerComponent>
          </>
        );
      case 'callTypes':
        return <ViewCallType />;
      case 'teams':
        return <ViewTeams />;
      default:
        return null;
    }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider width={250} collapsed={collapsed} style={{ background: 'white'}}>
          <Button
            type="text"
            color='primary'
            onClick={() => setCollapsed(!collapsed)}
            style={{ margin: '1rem', fontSize: '1rem', color: '#B388FF', float: 'right' }}
          >
            {collapsed ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
          </Button>
          {!collapsed && (
            <>
              <Typography style={{ fontSize: '1.2rem', fontWeight: '500', margin: '1rem', color: 'black', marginTop: '2.5rem'}}>
                {__("team.settings")}
              </Typography>
              <Typography style={{ fontSize: '1rem', margin: '1rem', color: 'black', marginTop: '1.5rem'}}>
                {__("team.account")}
              </Typography>
            </>
          )}
          <Menu
            mode="inline"
            defaultSelectedKeys={['users']}
            style={{borderRight: 0 }}
          >
            <Menu.Item key="users" onClick={() => setActiveSection('users')}>{__("team.users")}</Menu.Item>
            <Menu.Item key="teams" onClick={() => setActiveSection('teams')}>{__("team.teams")}</Menu.Item>
            <Menu.Item key="calltypes" onClick={() => setActiveSection('callTypes')}>{__("team.callTypes")}</Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ padding: "0 25px" }}>

            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
