import { Tabs, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { GenericExtractor } from "./GenericExtractor";
import { renderMapper } from "../../meeting/sections/ai/tabs/utils";
import styles from "./styles.module.css";
const map = (properties) => {
  return Object.values(properties || {}).reduce((prev, curr, index) => {
    const name = Object.keys(properties)[index];
    const o = {
      type: curr.enum ? "uniqueOption" : curr.type,
      name: String(name).toUpperCase().replace(/_/g, " "),
      description: curr.description,
      values: curr.items ? curr.items.enum : curr.enum,
      examples: curr.examples,
    };

    prev.push(o);

    return prev;
  }, []);
};

export const CallTypeTabs = (props) => {
  const [extractors, setExtractors] = useState([...props.extractors]);

  const extractorsMapped = useMemo(() => {
    return extractors.map((extractor) => ({
      name: extractor.callType.name,
      enabled: extractor.enabled,
      properties: map(extractor?.properties?.properties),
    }));
  }, [extractors]);

  const handleSaveExtractor = useCallback(
    (extractor, index) => {
      setExtractors((prevExtractors) => {
        const updatedExtractors = [...prevExtractors];
        updatedExtractors[index] = {
          ...updatedExtractors[index],
          enabled: extractor.enabled,
          properties: { properties: extractor.properties },
        };
        props.onSave(updatedExtractors);
        return updatedExtractors;
      });
    },
    [props]
  );

  const initialTabs = extractorsMapped.map((extractor, index) => ({
    key: extractor.name,
    label: (
      <Typography.Text
        className={styles.tab}
      >
        {extractor.name.toUpperCase()}
      </Typography.Text>
    ),
    content: (
      <GenericExtractor
        extractor={extractor}
        onSave={handleSaveExtractor}
        key={extractor.name}
        index={index}
      />
    ),
  }));
  return (
    <div>
      <Tabs
        type="card"
        defaultActiveKey="0"
        items={renderMapper(initialTabs)}
      />
    </div>
  );
};
