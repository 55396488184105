// src/features/team/interactionCallType/index.js
import React, { useEffect, useState } from 'react';
import { Layout, Typography, Table, Button, Modal, Menu, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from '../../../app/i18n';
import styles from "../team.module.css";
import NewCallType from "./NewCallType";
import EditCallType from './editCallType';
import { EditOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import {selectInteractionCallTypes, selectIsFetchingInteraction, deleteInteractionCallType} from './slice'
import ManagerComponent from '../../session/ManagerComponent';
import { selectTeams } from '../teams/slice';
const { Content } = Layout;

const SECTIONS = {
  NEW_CALLTYPE: "newCallType",
  EDIT_CALLTYPE: "editCallType",
};

export default function ViewCallType() {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const callTypesIsLoading = useSelector(selectIsFetchingInteraction);
  const callTypes = useSelector(selectInteractionCallTypes);
  const [section, setSection] = useState("");
  const [callTypeForEdit, setCallTypeForEdit] = useState();


  const handleDeleteCallType = (id) => {
    dispatch(deleteInteractionCallType({ id }));
  };

  const handleEditCallType = (callType) => {
    setCallTypeForEdit(callType);
    setSection(SECTIONS.EDIT_CALLTYPE);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Equipos',
      key: 'teams',
      render: (text, callType) => {
        const teamNames = callType.teams.map(teamId => {
          const team = teams.find(team => team._id === teamId);
          return team.name;
        });
        const joinedNames = teamNames.join(', ');

        if ((callType.teams.length === teams.length) || callType.teams.length === 0) {
          return "Todos";
        }

        return joinedNames.length > 32 ? `${joinedNames.substring(0, 32)}...` : joinedNames;
      },
    },
    {
      title: '',
      key: 'actions',
      render: (_, callType) => (
        <div style={{ display: 'flex'}}>
          <EditOutlined style={{ fontSize: "20px" }} onClick={() => handleEditCallType(callType)} />
          <DeleteOutlined style={{ marginLeft: '.5rem', fontSize: "20px", color: "red" }} onClick={() => handleDeleteCallType(callType._id)} />
        </div>
      ),
    },
  ];

  return (
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
        <Typography.Title level={2}>{__("team.callTypes")}</Typography.Title>
        <Button
          onClick={() => setSection(SECTIONS.NEW_CALLTYPE)}
          type="primary"
          className={styles.addTeamCircle}
        >
          +
        </Button>
      </div>
      {callTypesIsLoading && <Table columns={columns} dataSource={callTypes} />}
      
      <ManagerComponent>
        <NewCallType
          open={section === SECTIONS.NEW_CALLTYPE}
          onClose={() => setSection("")}
        />
        <EditCallType 
          open={section === SECTIONS.EDIT_CALLTYPE}
          callType={callTypeForEdit}  
          onClose={() => { setSection(""); setCallTypeForEdit(null); }}
        />
      </ManagerComponent>
    </Content>
  );
}
