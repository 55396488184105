import { Col, Menu, message, Modal, Row, Typography } from "antd";
import styles from "./MeetingDetailHeader.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting, openTeamSection, selectMeeting } from "./slice";
import { useState } from "react";
import moment from "moment";
import {
  EyeOutlined,
  TeamOutlined,
  FolderAddOutlined,
  TagOutlined,
  DeleteOutlined,
  LockOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import __ from "../../app/i18n";
import { useNavigate } from "react-router-dom";
import LibraryModal from "../library/Modal";
import { selectHasManagerAccess } from "../session/slice";
import { SamuScoreItem } from "../meetings/SamuScoreItem";
import PrivacyModal from "../privacy/Modal";
import MeetingTags from "../tags/MeetingTags";
import TagsModal from "../tags/TagsModal";
import ShareMeetingModal from "../share/ShareMeetingModal";
import { EditableText } from "../customComponents/EditableText/EditableText";
import API from "../../app/api";
import { useUserCanEditMeetingName } from "../../hooks/permissions/useUserCanEditMeetingName";

const MEETING_NAME_TEXT_FIELD_WIDTH = "400px";

export default function MeetingDetailHeader() {
  const meeting = useSelector(selectMeeting);
  const [messageApi, contextHolder] = message.useMessage();
  const [shareModalOpen, setShareModalOpen] = useState();
  const [libraryModalOpen, setLibraryModalOpen] = useState();
  const [tagModalOpen, setTagModalOpen] = useState();
  const [privacyModalOpen, setPrivacyModalOpen] = useState();
  const [modalOpen, setModalOpen] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasManagerAccess = useSelector(selectHasManagerAccess);
  const [meetingName, setMeetingName] = useState(meeting.name);
  const hasUserEditMeetingName = useUserCanEditMeetingName(meeting)


  const handleOpenSection = (section) => {
    dispatch(openTeamSection(section));
  };

  const openLibraryModal = () => {
    setLibraryModalOpen(true);
  };

  const openTagModal = () => {
    setTagModalOpen(true);
  };

  const openPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  const openShareModal = () => {
    setShareModalOpen(true);
  };

  const confirmDeleteMeeting = () => {
    setModalOpen(true);
  };

  const handleDeleteMeeting = () => {
    dispatch(deleteMeeting({ id: meeting.id }));

    return navigate("/meetings");
  };

  const handleSaveMeetingName = (value) => {
    setMeetingName(value);
    messageApi.open({
      type: "success",
      content: __('generic.alerts.meetingNameUpdated'),
    });
    API('updateMeetingName', { meetingId: meeting.id, name: value })
  };



  if (meeting && meeting.id && meeting.dateFrom) {
    return (
      <Row className={styles.container + " meeting-header-detail-container"}>
        <Col sm={18}>
          <div className={styles.meetingDetailHeader}>
            <SamuScoreItem size={50} meeting={meeting} />
            <div style={{ marginRight: "10px" }}>
              {contextHolder}
              {hasUserEditMeetingName ?
                <EditableText
                  level={5}
                  className={styles.meetingName}
                  value={meetingName}
                  onSave={handleSaveMeetingName}
                  minWidthInEditMode={MEETING_NAME_TEXT_FIELD_WIDTH}
                /> : <Typography.Title level={5} className={styles.meetingName}>{meetingName}</Typography.Title>
              }
              <Typography.Text className={styles.meetingDate} type="secondary">
                {moment(meeting.dateFrom).format("Do").replace("º", "") +
                  " de " +
                  moment(meeting.dateFrom).format("MMMM") +
                  " del " +
                  moment(meeting.dateFrom).format("YYYY")}{" "}
                ⋅ {moment(meeting.dateFrom).format("HH:mm")}
              </Typography.Text>
            </div>
            <MeetingTags meeting={meeting} />
          </div>
        </Col>
        <Col sm={6} pul={"right"}>
          <Menu style={{ textAlign: "right" }}>
            <Menu.Item
              style={{ width: "auto", display: "inline-block" }}
              key="share-meeting"
              icon={<ShareAltOutlined className={styles.menuItemLogo} />}
              onClick={() => openShareModal()}
            />

            {hasManagerAccess ? (
              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="visits-section"
                icon={<EyeOutlined className={styles.menuItemLogo} />}
                onClick={() => handleOpenSection("visits")}
              />
            ) : (
              ""
            )}

            <Menu.Item
              style={{ width: "auto", display: "inline-block" }}
              key="comments-section"
              icon={<TeamOutlined className={styles.menuItemLogo} />}
              onClick={() => handleOpenSection("comments")}
            />

            <Menu.Item
              style={{ width: "auto", display: "inline-block" }}
              key="add-to-library-meeting"
              icon={
                <FolderAddOutlined
                  className={styles.menuItemLogo}
                  key="ellipsis"
                />
              }
              onClick={() => openLibraryModal()}
            />

            <Menu.Item
              style={{ width: "auto", display: "inline-block" }}
              key="add-tag-meeting"
              icon={
                <TagOutlined className={styles.menuItemLogo} key="ellipsis" />
              }
              onClick={() => openTagModal()}
            />

            {hasManagerAccess ? (
              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="privacy-meeting"
                icon={<LockOutlined className={styles.menuItemLogo} />}
                onClick={() => openPrivacyModal()}
              />
            ) : (
              ""
            )}

            {hasManagerAccess ? (
              <Menu.Item
                style={{ width: "auto", display: "inline-block" }}
                key="delete-meeting"
                icon={<DeleteOutlined className={styles.menuItemLogo} />}
                onClick={() => confirmDeleteMeeting()}
              />
            ) : (
              ""
            )}
          </Menu>

          <Modal
            title={__("generic.fields.needConfirmTitle")}
            open={modalOpen}
            onOk={() => handleDeleteMeeting()}
            onCancel={() => setModalOpen(false)}
            okText={__("generic.fields.confirm")}
            cancelText={__("generic.fields.cancel")}
          >
            <p>{__("generic.fields.needConfirmContent")}</p>
          </Modal>
          <LibraryModal
            meeting={meeting}
            open={libraryModalOpen}
            onClose={() => setLibraryModalOpen(false)}
          />
          <TagsModal
            meeting={meeting}
            open={tagModalOpen}
            onClose={() => setTagModalOpen(false)}
          />
          <PrivacyModal
            meeting={meeting}
            open={privacyModalOpen}
            onClose={() => setPrivacyModalOpen(false)}
          />
          <ShareMeetingModal
            meeting={meeting}
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
          />
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
}
