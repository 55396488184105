import React, { useCallback, useState } from "react";
import Property from "./Property";
import { Button, Switch } from "antd";
import { IconDeviceFloppy, IconPlus } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.css";
import __ from "../../../app/i18n";

export const GenericExtractor = ({ extractor, onSave, index }) => {
  const [extractorState, setExtractorState] = useState({
    ...extractor,
    properties: extractor.properties?.map((p) => ({ ...p, id: uuidv4() })),
  });

  const handleNew = useCallback(() => {
    setExtractorState((prevState) => ({
      ...prevState,
      properties: [
        ...prevState.properties,
        {
          name: "",
          description: "",
          type: "uniqueOption",
          values: [],
          id: uuidv4(),
        },
      ],
    }));
  }, []);

  const onRemove = (id) => {
    setExtractorState((prevState) => {
      return {
        ...prevState,
        properties: prevState.properties.filter(
          (property) => property.id !== id
        ),
      };
    });
  };

  const onUpdateProperty = ({ index, ...prop }) => {
    const updatedProperties = [...extractorState.properties];
    updatedProperties[index] = prop;
    const extractorUpdated = {
      ...extractorState,
      properties: updatedProperties,
    };
    setExtractorState(extractorUpdated);
  };

  const onEnableExtractor = () => {
    onSave({ ...extractorState, enabled: !extractorState.enabled }, index);
    setExtractorState((prevState) => ({
      ...prevState,
      enabled: !prevState.enabled,
    }));
  };
  return (
    <div>
      <div className={styles.genericExtractorContainer}>
        <div className={styles.genericExtractorButtonsContainer}>
          <Button
            type="primary"
            className={styles.genericExtractorButton}
            icon={
              <IconDeviceFloppy
                size={"20px"}
                stroke={2}
                className={styles.genericExtractorIconButton}
              />
            }
            disabled={!extractorState.enabled}
            onClick={() => onSave(extractorState, index)}
          >
            {__("apps.extractor.buttons.save")}
          </Button>
          <Button
            type="primary"
            className={styles.genericExtractorButton}
            icon={
              <IconPlus
                size={"20px"}
                stroke={2}
                className={styles.genericExtractorIconButton}
              />
            }
            disabled={!extractorState.enabled}
            onClick={handleNew}
          >
            {__("apps.extractor.buttons.add")}
          </Button>
        </div>
        <Switch checked={extractorState.enabled} onChange={onEnableExtractor} />
      </div>
      {extractorState?.enabled &&
        extractorState.properties?.map((p, i) => (
          <Property
            {...p}
            index={i}
            key={p.id}
            onUpdateProperty={onUpdateProperty}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
};
