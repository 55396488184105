import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import {
  CheckCircleOutlined,
} from "@ant-design/icons";
import __ from "../../../app/i18n";
import { changePassword, closeChangePasswordModal, openChangePasswordModal, selectIsChangePasswordModal, selectIsFetchingPassword } from "../slice";

const ChangePasswordSection = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const isModalOpen = useSelector(selectIsChangePasswordModal);
  const isFetchingPassword = useSelector(selectIsFetchingPassword);
  
  const showModal = () => {
    dispatch(openChangePasswordModal())
  };

  const handleOk = () => {
    dispatch(changePassword({ currentPassword, newPassword }));
  };
  const handleCancel = () => {
    dispatch(closeChangePasswordModal())
  };

  const handleNewPassword = (newPassword) => {
    setNewPassword(String(newPassword).replace(/ /g, ''));
  }

  const hasMinLength = () => String(newPassword).length >= 12;
  const hasUppercaseChar = () =>  /[A-Z]/.test(newPassword);
  const hasNumberChar = () => /[0-9]/.test(newPassword);

  const isValidPassword = hasMinLength() && hasUppercaseChar() && hasNumberChar();
  
  return (
    <>
      <Button onClick={showModal}>{__("generic.fields.changePassword")}</Button>
      <Modal
        title={__("generic.fields.changePassword")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={__("generic.fields.changePassword")}
        okButtonProps={{
          disabled: (!isValidPassword || !String(currentPassword).trim())
        }}
        confirmLoading={isFetchingPassword}
      >
        <Form layout={'vertical'}>
          <Form.Item label="Confirmar contraseña actual">
            <Input.Password onChange={v => setCurrentPassword(v.target.value)}/>
          </Form.Item>
          <Form.Item label="Nueva Contraseña">
            <Input.Password onChange={v => handleNewPassword(v.target.value)}/>
            <ul style={{ listStyle: 'none', padding: "25px 0 0 0" }}>
              <li>
                <CheckCircleOutlined style={{...{ fontSize: "16px" }, ...hasMinLength() ? { color: 'green' } : {}}}/> Al menos 12 caracteres de longitud
              </li>
              <li>
                <CheckCircleOutlined style={{...{ fontSize: "16px" }, ...hasUppercaseChar() ? { color: 'green' } : {}}}/> Un carácter en mayuscula
              </li>
              <li>
                <CheckCircleOutlined style={{...{ fontSize: "16px" }, ...hasNumberChar() ? { color: 'green' } : {}}}/> Un número
              </li>
            </ul>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ChangePasswordSection;
