import { Tabs } from "antd";
import BaseMethodologySalesSummaryTab from "./BaseMethodologySalesSummaryTab";
import { useState } from "react";
import { renderMapper } from "./utils";

const initialTabs = [
    {
        label: "SPICED",
        content: <BaseMethodologySalesSummaryTab methodologyName='Spiced' reportType='spiced' />,
    },
    {
        label: "BANT",
        content: <BaseMethodologySalesSummaryTab methodologyName='Bant' reportType='bant' />,
    },
]

export const BaseMethodologySalesSummaryContainer = () => {
    const [tabs] = useState(renderMapper(initialTabs));

    return (<Tabs
        type="card"
        defaultActiveKey="1"
        items={tabs}
    />
    );
}